import Swiper from 'swiper';
import 'swiper/css/swiper.css';
require('../scss/_styles.scss');

(window as any).appSwiperInit = function appSwiperInit(moduleID: string, autoplay: string, speed: string, effectDefaults: any, fallback: any) {
  var configured = {
    autoplay: (autoplay === 'True'),
    speed: speed
  };
  var merged = Object.assign(fallback, effectDefaults, configured);
  var mySwiper = new Swiper (`.swiper-${moduleID}`, merged);
}

var galleryThumbs = new Swiper('.swiper-thumbs', {
  spaceBetween: 0,
  slidesPerView: 2,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  loop: true,
  freeMode: true,
  allowTouchMove: true,
  breakpoints: {
    640: {
      spaceBetween: 0,
      slidesPerView: 6,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      loop: false,
      freeMode: false,
      allowTouchMove: false,
    },
    768: {
      spaceBetween: 0,
      slidesPerView: 6,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      loop: false,
      freeMode: false,
      allowTouchMove: false,
    }
  }
});

var galleryTop = new Swiper('.swiper-top', {
  spaceBetween: 0,
  loop: true,
  effect: 'fade',
  thumbs: {
      swiper: galleryThumbs,
  }
});